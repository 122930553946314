.explanation {
    padding: 50px 20px;
    background-color: #fff;
    text-align: center;
    color: #333;
    font-size: 1.2em;
    line-height: 1.6;
  }
  
  .explanation p {
    margin-bottom: 20px;
  }
  
  .steps {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .step {
    margin: 0 20px;
    text-align: left;
  }
  
  .step h3 {
    font-size: 1.5em;
    color: #185a9d;
  }
  
  .step p {
    font-size: 1.1em;
  }
  
  .donation-link {
    display: inline-block;
    margin-top: 20px;
    padding: 15px 30px;
    background: #43cea2;
    color: #fff;
    text-decoration: none;
    border-radius: 50px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    transition: background 0.3s, transform 0.3s;
  }
  
  .donation-link:hover {
    background: #185a9d;
    transform: translateY(-2px);
  }
  