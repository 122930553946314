.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #fff;
  text-align: center;
  padding: 0 20px;
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
  filter: brightness(0.5); /* Adjust brightness to make text more readable */
}

.hero-content {
  z-index: 1;
}

.hero-content h1 {
  font-size: 4em;
  font-weight: 900;
  margin: 0;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards; /* Ensures the text stays visible after fading in */
}

.hero-content p {
  font-size: 1.5em;
  margin-top: 20px;
}

/* Keyframe for fading in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
