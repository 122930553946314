.footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .footer ul {
    list-style: none;
    padding: 0;
  }
  
  .footer ul li {
    display: inline;
    margin: 0 10px;
  }
  
  .footer ul li a {
    color: #ff7e5f;
    text-decoration: none;
  }
  
  .footer ul li a:hover {
    text-decoration: underline;
  }
  
  .footer p {
    margin-top: 15px;
    font-size: 0.9em;
  }
  