.statements {
  padding: 50px 20px;
  background: linear-gradient(135deg, #43cea2, #185a9d);
  color: #fff;
  text-align: center;
}

.statement {
  margin: 30px 0;
  font-size: 2em;
  font-weight: bold;
  animation: fadeInUp 2s ease-in-out;
}

.statement:nth-child(even) {
  font-size: 1.8em;
  color: #ffecd2;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Optional: Parallax Scrolling Effect */
.statements {
  background-attachment: fixed;
  background-size: cover;
}

.statement h2 a {
  color: #fff;
  text-decoration: none;
  transition: color 0.2s;
}

.statement h2 a:hover {
  color: #ffecd2;
  text-decoration: underline;
}

